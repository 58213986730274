import React from 'react'
import './styles/Page.css'

import NavBar from './NavBar'
import Header from './Header'
import Segments from './Segments'
import Footer from './Footer'

import { loadReCaptcha } from 'react-recaptcha-google'

class Page extends React.Component {

    componentDidMount() {
        loadReCaptcha();
    }

    render (){
        return (
            <div id="page-top" data-spy="scroll" data-target=".navbar-fixed-top" data-offset="70"  >
                <NavBar/>
                <Header />
                <Segments/>
                <Footer/>
            </div>
        )
    }
}

export default Page