import React from 'react'
import MapContainer from './MapContainer'
import './styles/SegmentContacto.css'
import ScrollReveal from 'scrollreveal'
import { ReCaptcha } from 'react-recaptcha-google'

class SegmentContacto extends React.Component {

    constructor(props, context) {
        super(props, context);
        this.onLoadRecaptcha = this.onLoadRecaptcha.bind(this);
        this.verifyCallback = this.verifyCallback.bind(this);
    }

    /*
    constructor(props) {
        super(props);

        
    }*/
    componentDidMount() {
        ScrollReveal().reveal('.sr-text-1c', {
            duration: 1000,
            scale: 0.3,
            distance: '0px'
        }, 200);
        ScrollReveal().reveal('.sr-text-2c', {
            duration: 1000,
            scale: 0.3,
            distance: '0px'
        }, 400);
        ScrollReveal().reveal('.sr-text-3c', {
            duration: 1000,
            scale: 0.3,
            distance: '0px'
        }, 600);
        ScrollReveal().reveal('.sr-text-4c', {
            duration: 1000,
            scale: 0.3,
            distance: '0px'
        }, 800);


        if (this.captchaDemo) {
            console.log("started, just a second...")
            this.captchaDemo.reset();
        }

    }

    onLoadRecaptcha() {
        if (this.captchaDemo) {
            this.captchaDemo.reset();
        }
    }
    
    verifyCallback(recaptchaToken) {
      // Here you will get the final recaptchaToken!!!  
      console.log(recaptchaToken, "<= your recaptcha token")
    }

    render (){
        return (
            <section className="no-padding" id="contacto">

                <div id="map" className="text-center" >
                    <MapContainer></MapContainer>
                </div>

                <div id="form-contacto">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-6">
                                <h1 className="section-subheading sr-text-1c">Háganos llegar su consulta</h1>
                                <p className="text-highlight sr-text-2c">
                                    Nuestros servicios son aplicables a un universo de necesidades. Háganos llegar su consulta, a la brevedad nos pondremos en contacto.
                                </p>
        
                                <div className="info-lugar sr-text-3c">
                                    <div className="name">
                                        VOLTION S.R.L.<br/>
                                        INGENIERÍA ELECTRÓNICA
                                    </div>
        
                                    <div className="direccion">
                                        Juan B. Justo 1490<br/>
                                    (S2013BON) Rosario - Argentina <br/>
                                    </div>
        
                                </div>
                            </div>
        
                            <div className="col-md-6">
                                <form className="form-horizontal sr-text-4c">
                                    <div className="form-group">
                                        <input type="text" name="NA" className="form-control" placeholder="Nombre y Apellido" />
                                    </div>
                                    <div className="form-group">
                                        <input type="email" name="EM" className="form-control" placeholder="Email" />
                                    </div>
                                    <div className="form-group">
                                        <input type="text" name="PH" className="form-control" placeholder="Teléfono de Contacto" />
                                    </div>
                                    <div className="form-group">
                                        <textarea className="form-control" name="TX" placeholder="Motivo de Consulta" rows="4"></textarea>
                                    </div>
        
                                    <div className="row"  >
                                        <div className="col-sm-6">
                                            <ReCaptcha
                                                ref={(el) => {this.captchaDemo = el;}}
                                                size="normal"
                                                render="explicit"
                                                sitekey="6Lfq7ukUAAAAAFqo-byDWC4SJjRWdzBK72XlVpzD"
                                                onloadCallback={this.onLoadRecaptcha}
                                                verifyCallback={this.verifyCallback}
                                            />
                                        </div>
                                        <div className="col-sm-6">
                                            <button type="submit" formAction="/contacto.php" formMethod="POST" className="btn btn-primary btn-xl page-scroll pull-right">ENVIAR</button>
                                        </div>
                                    </div>
        
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            
            </section>
        )
    }
}

export default SegmentContacto