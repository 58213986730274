import React from 'react'

import './styles/SegmentHacemos.css'
import ScrollReveal from 'scrollreveal'
//import ReactDOM from 'react-dom'


class SegmentHacemos extends React.Component {

    componentDidMount() {

        const sr = ScrollReveal();

        sr.reveal('.sr-text-1b', {
            duration: 1000,
            scale: 0.3,
            distance: '0px'
          }, 200);
          sr.reveal('.sr-text-2b', {
            duration: 1000,
            scale: 0.3,
            distance: '0px'
          }, 400);
          sr.reveal('.sr-text-3b', {
            duration: 1000,
            scale: 0.3,
            distance: '0px'
          }, 600);
          sr.reveal('.sr-text-4b', {
            duration: 1000,
            scale: 0.3,
            distance: '0px'
          }, 800);
    }

    render (){

        return (
            <section id="que-hacemos">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6"></div>
                        <div className="col-lg-6 texto">
                            <h3 className="section-heading sr-text-1b">QUÉ HACEMOS</h3>
                            <h2 className="section-subheading sr-text-2b">Desarrollamos</h2>
                            <p className="text-highlight sr-text-3b">
                                Desarrollamos productos propios y para terceros de forma íntegra, desde el diseño de 
                                la electrónica utilizada (hardware), y todo el software que involucra (tanto firmware 
                                como software). El manejo propio de los detalles y la electrónica nos permite brindar 
                                el mejor soporte personalizado a las empresas que integran nuestros productos y a 
                                nuestros clientes en general. 
                            </p>
                            <h2 className="section-subheading sr-text-2b">Post Venta</h2>
                            <p className="text-faded sr-text-4b">
                                Una de las mayores ventajas de nuestros productos es el servicio de posventa. Contamos
                                siempre con stock de repuestos y además con la posibilidad de que el producto perdure 
                                en el tiempo a través de expansiones,  actualizaciones de software y/o de hardware 
                                permitiéndole evolucionar junto con la tecnología, alargando su vida útil.
                            </p>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}

export default SegmentHacemos