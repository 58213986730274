import React from 'react'
import './styles/NavBar.css'
import $ from 'jquery'

class NavBar extends React.Component {

    componentDidMount() {
        // https://www.codeply.com/go/LDHQhWUG0x

        var toggleAffix = function(affixElement, scrollElement, wrapper) {
  
            var height = affixElement.outerHeight(),
                top = wrapper.offset().top;
            
            if (scrollElement.scrollTop() >= (top + 1 )){

                // Ya escroleando un poco
                wrapper.height(height);
                affixElement.addClass("affix");
                

            } else {
                // Al Inicio, con la barra ON TOP
                affixElement.removeClass("affix");
                wrapper.height('auto');
                
            }

        };
          
        
        $('[data-toggle="affix"]').each(function() {
            var ele = $(this),
                wrapper = $('<div></div>');
            
            ele.before(wrapper);
            $(window).on('scroll resize', function() {
                toggleAffix(ele, $(this), wrapper);
            });
            
            // init
            toggleAffix(ele, $(window), wrapper);
        });
    }
          
    render (){

        return (
            <nav className="navbar navbar-default navbar-expand-sm navbar-light" data-toggle="affix">
                <a className="navbar-brand" href="#page-top">
                    <div className="logo-top"></div>
                </a>
                <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#collapsingNavbar">☰</button>
                <div className="collapse navbar-collapse" id="collapsingNavbar">
                    <ul className="nav navbar-nav ml-auto">
                        
                        <li className="nav-item">
                            <a className="nav-link" href="#solucion-a-medida">LA SOLUCION A MEDIDA</a>
                        </li>
                        <li className="nav-item ">
                            <a className="nav-link" href="#que-hacemos">QUÉ HACEMOS</a>
                        </li>
                        <li className="nav-item ">
                            <a className="nav-link" href="#contacto">CONTACTO</a>
                        </li>
                    </ul>
                </div>
            </nav>
        )
    }
}

export default NavBar