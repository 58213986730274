import React from 'react'
import ReactDOM from 'react-dom'
import 'bootstrap/dist/css/bootstrap.css'
import 'font-awesome/css/font-awesome.min.css'; 
import './styles/index.css'

import Page from './components/Page'



const container = document.getElementById("root")

ReactDOM.render(<Page/>, container)

