import React from 'react'

import './styles/Segments.css'

import SegmentSolucion from './SegmentSolucion'
import SegmentHacemos from './SegmentHacemos'
import SegmentContacto from './SegmentContacto'


class Segments extends React.Component {

        render (){
            return (
                <div id="Main" className="Segments" >                    
                    <SegmentSolucion />
                    <SegmentHacemos />
                    <SegmentContacto />
                </div>
            )
        }
    }
    
export default Segments