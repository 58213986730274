import React from 'react'
import voltionLogo from '../images/logo_home.png'
import './styles/Header.css'
import $ from 'jquery'
import 'jquery-ui'

class Header extends React.Component {

    componentDidMount() {
        /*
        // jQuery for page scrolling feature - requires jQuery Easing plugin
        $('a.page-scroll').bind('click', function(event) {
            var $anchor = $(this);
            $('html, body').stop().animate({
                scrollTop: ($($anchor.attr('href')).offset().top - 62)
            }, 1250, 'easeInOutExpo');
            event.preventDefault();
        });
*/
        
  
    }

    gotoOther(){
            
    }

    render (){
        return (
            <header  >
                <div className="header-content">
                    <div className="header-content-inner" >
                        <img alt="Voltion" src={voltionLogo} />
                        <h1 id="homeHeading">Sistemas electrónicos e informáticos para brindar respuestas sencillas a problemas complejos</h1>
                        <p>
                            Innovación y experiencia para dar soluciones de ingeniería electrónica
                            a empresas de desarrollo y otros.
                        </p>
                        <a href="#solucion-a-medida" className="btn btn-primary btn-xl page-scroll" onClick={(event) => {
                            // jQuery for page scrolling feature - requires jQuery Easing plugin
                            
                            var url = new URL(event.target.href);
                            var tgt = url.href.substring(url.origin.length+1)
                            $('html, body').stop().animate({
                                scrollTop:($(tgt).offset().top - 62)
                            }, 1250); // , 'easeInOutExpo'
                            
                            event.preventDefault();
                        }}>CONÓZCANOS</a>
                    </div>
                </div>
            </header>
        )
    }
}

export default Header