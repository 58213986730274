import React, { Component } from 'react';
import { Map, GoogleApiWrapper, Marker, InfoWindow } from 'google-maps-react';
import vltIcon from './images/pin_map.png'

const mapStyles = {
  width: '100%',
  height: '100%'
};

export class MapContainer extends Component {


  

  onMarkerClick(){
    // https://goo.gl/maps/gWPVacWV2BYypW328 
    // 
    window.open("https://goo.gl/maps/gWPVacWV2BYypW328", "_blank") //to open new page
  }

  render() {

    // const iframe = '<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3349.4001866198973!2d-60.68624668405498!3d-32.914023577569!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x95b6535d7be0be77%3A0x733ce6f00a3ce3cd!2sVOLTION%20SRL!5e0!3m2!1ses-419!2sar!4v1586990645698!5m2!1ses-419!2sar" width="600" height="450" frameborder="0" style="border:0;" allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>';

    return (
      <Map
        google={this.props.google}
        zoom={16}
        style={mapStyles}
        initialCenter={{
         lat: -32.914020,
         lng: -60.684062
        }}
      >
        <Marker
            icon={vltIcon}
            onClick={this.onMarkerClick}
            name={'VOLTION SRL'}
        />
      </Map>

    );
  }
}

export default GoogleApiWrapper({
  apiKey: 'AIzaSyAj5cVqX4aYwGU1aElEQ0EdAz9oqtcNYC4'
})(MapContainer);