import React from 'react'
import './styles/Footer.css'
import afip from '../images/img_data_fiscal.png'
import f960 from '../images/F960.pdf'
import logofooter from '../images/ic_logo_footer@2x.png'

// <div className="col-sm-6" style={"margin-top: 15px"}>
// <img src={afip} alt="Data Fiscal"  style="margin-left: 20px"/>

class Footer extends React.Component {
    render (){
        return (
            <footer>
                <div className="container">
                    <div className="row">
                        <div className="col-sm-6"  style={{ marginTop: `15px` }} >
                            <img src={logofooter} width="40" alt="Voltion" className="pull-left"/>
                            <div className="footer-name pull-left">
                                <div className="company-name">VOLTION INGENIERÍA ELECTRÓNICA</div>
                                <div>® 2020 - Todos los derechos reservados</div>
                            </div>
                        </div>
                        <div className="col-sm-6">
                            <div className="pull-right">
                                Síguenos 
                                <a href="https://web.facebook.com/voltionsrl/?fref=ts" className="social-container">
                                    <i className="fa fa-facebook"></i>
                                </a>
                                <a href="https://www.linkedin.com/company/voltion" className="social-container">
                                    <i className="fa fa-linkedin"></i>
                                </a>
                                <a href={f960}>
                                    <img src={afip} alt="Data Fiscal"  style={{marginLeft: `20px` }} />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        )
    }
}

export default Footer