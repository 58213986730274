import React from 'react'
import './styles/SegmentSolucion.css'
import ScrollReveal from 'scrollreveal'
//import ReactDOM from 'react-dom'


class SegmentSolucion extends React.Component {

    componentDidMount() {

        const sr = ScrollReveal();

        sr.reveal('.sr-text-1', {
            duration: 1000,
            scale: 0.3,
            distance: '0px'
          }, 200);
          sr.reveal('.sr-text-2', {
            duration: 1000,
            scale: 0.3,
            distance: '0px'
          }, 400);
          sr.reveal('.sr-text-3', {
            duration: 1000,
            scale: 0.3,
            distance: '0px'
          }, 600);
          sr.reveal('.sr-text-4', {
            duration: 1000,
            scale: 0.3,
            distance: '0px'
          }, 800);
    }

    render (){

        return (
            <section id="solucion-a-medida">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 texto">
                            <h3 className="section-heading sr-text-1">LA SOLUCIÓN A MEDIDA</h3>
                            <h2 className="section-subheading sr-text-2">Pensamos en soluciones Integrales</h2>
                            <p className="text-highlight sr-text-3">
                                Con la constante evolución de la tecnología es necesario estar capacitado, es por eso que invertimos
                                en I+D día a día, para poder darles a nuestros clientes el mejor asesoramiento y solución a un 
                                problema determinado, tanto a clientes directos como a empresas tecnológicas que tengan la necesidad
                                de contar con un producto personalizado, e incluso integrarlo con existentes, 
                                potenciando así sus proyectos.
                            </p>
                            <h2 className="section-subheading sr-text-1">Conózcanos</h2>
                            <p className="text-faded sr-text-4">
                            En el año 2015 en la ciudad de Rosario, nace VOLTION S.R.L. con el claro objetivo 
                            de desarrollar proyectos de industria nacional brindando soluciones tecnológicas de
                            control, telemetría y comunicaciones, aportando conocimiento y calidad pero además
                            compromiso que perdura en el tiempo, tanto para con nuestros clientes como proveedores.
                            </p>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}

export default SegmentSolucion